<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="140px" class="login-form">
            <div class="zt-block">
                <div class="zt-block-head">基本信息</div>
                <div style="padding:20px;">
                    <el-form-item prop="info.legalName" label="法人姓名">
                        <el-input v-model="ruleForm.info.legalName" placeholder="请输入" style="max-width:250px;"></el-input>
                        <el-checkbox style="margin-left:24px" v-model="ruleForm.info.isYb">一般纳税人</el-checkbox>
                    </el-form-item>
                    <el-form-item prop="info.area" label="企业现有厂房面积">
                        <div class="flex">
                            <el-input v-model="ruleForm.info.area" placeholder="请输入" style="max-width:250px;"></el-input>
                            <div style="margin-left:24px">M²</div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="info.unitCount" label="当年的申请单元数">
                        <el-input-number v-model="ruleForm.info.unitCount" :min="1" placeholder="请输入"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="info.taxAmount" label="当年的税收">
                        <div class="flex">
                            <el-input v-model="ruleForm.info.taxAmount" placeholder="请输入" style="max-width:250px;"></el-input>
                            <div style="margin-left:24px">万元</div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="info.address" label="现有厂房所在地">
                        <el-select v-model="ruleForm.info.address" style="width:240px" placeholder="请选择">
                            <el-option v-for="item in zoneOptions" :key="item.id" 
                            :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="info.staffCount" label="企业员工数">
                        <el-select v-model="ruleForm.info.staffCount" style="width:250px" placeholder="请选择">
                            <el-option v-for="item in empOptions" :key="item.id"
                            :label="item.label" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="info.revenue" label="主要营业收入">
                        <div class="flex">
                            <el-input v-model="ruleForm.info.revenue" placeholder="请输入" style="max-width:250px;"></el-input>
                            <div style="margin-left:24px">万元</div>
                        </div>
                    </el-form-item>
                    <el-form-item style="min-width:300px;max-width:50%;position:relative;" prop="info.desc" label="备注">
                        <el-input type="textarea" v-model="ruleForm.info.desc" :rows="3" :maxlength="300" placeholder="请输入"></el-input>
                        <div style="position:absolute;right:14px;bottom:0">
                            <span style="line-height: 25px;color:#B3B3B3;" class="font14-grey">{{ruleForm.info.desc && ruleForm.info.desc.length || 0}}/300</span>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">基本信息</div>
                <div style="padding:20px 0;">
                    <el-form-item prop="info.meters" label="用电表号">
                        <el-input v-model="ruleForm.info.meters" placeholder="请输入" style="max-width:250px;"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div style="padding: 10px 20px 30px;">
                <el-button @click="hide()" style="width: 75px;" size="small" type="primary" plain>取消</el-button>
                <el-button @click="submitForm()" style="width: 75px;" size="small" type="primary">提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { findItem } from "@/util";
    import { applySave,getApplyId } from '@/service/factory';
    import { getFactoryZones } from "@/service/company";
    import { getCompanyInfo } from "@/service/sale";
    export default {
        components: {
            
        },
        data() {
            let checkNum = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入当年的申请单元数'));
                } else {
                    const reg = /^[1-9]\d*$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请填写正整数'));
                    }
                }
            };
            return {
                empOptions: [
                    { id: 1, label: '1-20人' }, 
                    { id: 2, label: '21-50人' }, 
                    { id: 3, label: '51-100人' }, 
                    { id: 4, label: '101-200人' }, 
                    { id: 5, label: '201-500人' }, 
                    { id: 6, label: '501-1000人' }, 
                    { id: 7, label: '1001人以上' }
                ],
                zoneOptions: [],
                rules: {
                    [`info.legalName`]: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
                    [`info.area`]: [{ required: true, message: '请输入企业现有厂房面积', trigger: 'blur' }],
                    [`info.unitCount`]: [{ validator: checkNum, trigger: 'blur' }],
                    [`info.taxAmount`]: [{ required: true, message: '请输入当年的税收', trigger: 'blur' }],
                    [`info.address`]: [{ required: true, message: '请选择现有厂房所在地', trigger: 'blur' }],
                    [`info.staffCount`]: [{ required: true, message: '请选择企业员工数', trigger: 'blur' }],
                    [`info.revenue`]: [{ required: true, message: '请输入主要营业收入', trigger: 'blur' }],
                    [`info.meters`]: [{ required: true, message: '请输入用电表号', trigger: 'blur' }],
                },
                ruleForm:{
                    type:"join",
                    info:{
                        legalName:null,
                        isYb:false,
                        area:null,
                        unitCount:null,
                        taxAmount:null,
                        address:null,
                        staffCount:null,
                        revenue:null,
                        desc:null,
                        meters:null,
                    },
                    submitNow:true,
                },
                comp:null,
                aptitude:null,
            }
        },
        async created() {
            this.zoneOptions = await getFactoryZones();
            this.comp = this.$store.state.loginUser.comp;
            // 回填法人姓名
            if (this.comp && this.comp.principal_type==1 && this.comp.legal_name) {
                this.ruleForm.info.legalName = this.comp.legal_name;
            }
            if (this.comp && this.comp.principal_type==2 && this.comp.agent_name) {
                this.ruleForm.info.legalName = this.comp.agent_name;
            }
            if (this.comp && this.comp.id) {
                this.aptitude = await getCompanyInfo(this.comp.id);
                console.log(this.aptitude);
                // 回填企业现有厂房面积、企业员工数、现有厂房所在地
                if (this.aptitude.aptitude && this.aptitude.aptitude.stat) {
                    this.ruleForm.info.area = this.aptitude.aptitude.stat.area;
                    this.ruleForm.info.staffCount = this.aptitude.aptitude.stat.n_employee;
                    this.ruleForm.info.address = this.aptitude.aptitude.stat.zone;
                }
            }
        },
        mounted() {
            if (this.$route.query.id) {
                getApplyId(this.$route.query.id).then(rst => {
                    if (rst) {
                        this.ruleForm = {
                            type:"join",
                            info:{
                                legalName:rst.info.legalName,
                                isYb:rst.info.isYb,
                                area:rst.info.area,
                                unitCount:rst.info.unitCount,
                                taxAmount:rst.info.taxAmount,
                                address:rst.info.address,
                                staffCount:rst.info.staffCount,
                                revenue:rst.info.revenue,
                                desc:rst.info.desc,
                                meters:rst.info.meters,
                            },
                            submitNow:true,
                        };
                    }
                    console.log(rst);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            }
        },
        methods: {
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            hide() {
                this.$router.push("/factory/qyrg");
            },
            submit() {
                console.log(JSON.stringify(this.ruleForm));
                if (this.ruleForm && this.ruleForm.info.address) {
                    this.ruleForm.info.address = findItem(this.zoneOptions,this.ruleForm.info.address).name;
                }
                applySave(this.ruleForm).then(rst => {
                    this.hide();
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../listStyle.css"></style>
<style scoped>
    
    
</style>